import React from "react"
import { graphql } from "gatsby"
import { theme, siteEmail, instagram } from '../../config/website'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import SEO from "../components/SEO/SEO"

const Container = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 48px;
    grid-template-columns: 3.5fr 6.5fr;
    grid-template-rows: auto;
    margin-right: 48px;
  }
`

const FeaturedContainer = styled.div`

`

const ImageContainer = styled.div`
  @media (min-width: 768px) {
    position: relative;
    width: 100%;
    align-self: start;

    ::before {
      content: "";
      display: block;
      height: 0;
      width: 0;
      padding-top: 80%;
    }
  }
`

const FeaturedImageContainer = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const FeaturedImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const TextContainer = styled.div`
  align-self: stretch;
`

const Title = styled.div`
  margin-top: 24px;
`

const Name = styled.h1`
  margin-bottom: 8px;
  font-family: Edmondsans-Medium;
  &&& {
    font-size: 34px;
  }
`

const Contact = styled.p`
  &&& {
    margin: 2px 0px;
  }
`

const ContactLabel = styled.span``

const ContactLink = styled.a`
  color: ${theme.primaryColor};
  text-decoration: underline;

  :hover, :active {
    color: black;
    text-decoration: none;
  }
`

const Mail = styled.span``

const Content = styled.div`
  h2 {
    font-family: Edmondsans-Medium;
    margin: 32px 0 8px 0;
  }
  p {
    margin: 8px 0px;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.primaryColor};
    font-family: Edmondsans-Medium;

    :hover, :active {
      color: black;
      text-decoration: none;
    }
  }
`

const About = ({ data: { prismicAbout, allFile }}) => {

  const data = {
    image: prismicAbout.data.image.localFile.childImageSharp.gatsbyImageData,
    contentHTML: prismicAbout.data.content.html
  }

  const seo = {
    title: "About"
  }

  return (
    <Container>
      <SEO meta={seo} />
      <FeaturedContainer>
        <ImageContainer>
          <FeaturedImageContainer>
            <FeaturedImage image={data.image} alt="" />
          </FeaturedImageContainer>
        </ImageContainer>
        <Title>
          <Name>Ed Hughes</Name>
          <Contact>
            <Mail>{siteEmail}</Mail>
          </Contact>
          <Contact>
            <ContactLabel>Instagram: </ContactLabel>
            <ContactLink href={instagram.profileURL} target="_blank">{instagram.profileName}</ContactLink>
          </Contact>
        </Title>
      </FeaturedContainer>
      <TextContainer>
        <Content dangerouslySetInnerHTML={{ __html: data.contentHTML }} />
      </TextContainer>
    </Container>
  )
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    prismicAbout {
      id
      data {
        content {
          html
        }
        image {
          localFile {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
      }
    }
  }
`